import React, {useState, useEffect, useRef} from "react";
import {Container, Tabs, Tab} from 'react-bootstrap';
import NaviBarv2 from './Components/NaviBarv2';
import ModelingSEIR_HCD from './ModelingSEIR_HCD'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles.css";
import social1 from "./images/directions/social1.png"

import Footer from './Components/Footer'



export function Social_processes(){


  return(
  <>
  <NaviBarv2 />
      <Container className="my-3"   style={{
              height: "120%" }}>
                <p class="lead my-4">Построена и проанализирована модель среднего поля, которая описывает процесс распространения информации в онлайн социальных сетях с учетом внешнего воздействия. При этом под расстоянием понимается минимальное количество связей между источником и пользователем, и в модели учитываются процессы распространения информации с увеличением расстояния до источника (контент-ориентированный процесс) и при одинаковом расстоянии до источника (структурно-ориентированный процесс).</p>
         <div class="center"><img src={social1} align="center" width={600} /></div>
         <p class="lead my-4">Определена оптимальная стратегия поведения пользователей, вовлеченных в процесс распространения информации, при условии ограничений на распространения информации. Изучена чувствительность параметров модели, влияющих на управление, и начальных условий. </p>
         <p class="lead my-4">Сформулированы обратные задачи определения начального условия по дополнительной информации о процессе распространения информации при добавлении управления. Обратные задачи отличались между собой функцией управления и были сведены к задаче минимизации функционалов невязки. С помощью байесовской оптимизации численно исследованы решения полученных обратных задач для синтетических данных. </p>
    </Container>
    <Footer />
  </>
)}